<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" class="search-form" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单编号" prop="businessName">
              <el-input v-model="queryInfo.condition.businessName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单状态" prop="scope">
              <el-select class="input" v-model="queryInfo.condition.scope" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交易日期" prop="applicantTime">
              <el-date-picker v-model="queryInfo.applicantTime" type="daterange" @change="datechange"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配送员手信息" prop="linkPhone">
              <el-input v-model="queryInfo.condition.linkPhone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单类型" prop="scope">
              <el-select class="input" v-model="queryInfo.condition.scope" clearable>
                <el-option v-for="item in options1" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end;margin-top: 18px;">
            <el-button type="primary" @click="search" icon="el-icon-search">查询</el-button>
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <div class="bill-info">
        <div class="flex-column bill-info-item">
          <div>总配送费</div>
          <div>￥{{ 1465 }}</div>
        </div>
        <div class="flex-column bill-info-item">
          <div>基础配送费</div>
          <div>￥{{ `0.00` }}</div>
        </div>
        <div class="flex-column bill-info-item">
          <div>总配送费</div>
          <div>￥{{ `0.00` }}</div>
        </div>
        <div class="flex-column bill-info-item">
          <div>总配送费</div>
          <div>￥{{ `0.00` }}</div>
        </div>
      </div>
    </div>
    <div class="tab">
      <div class="btn-box">
        <div>
          <el-button type="primary" v-if="billdetail" @click="backlist">返回</el-button>
        </div>
        <div class="add" style="text-align: right;">
          <el-button type="primary" @click="download">导出</el-button>
        </div>
      </div>
      <el-table v-if="!billdetail" :data="tableData" style="width: 100%" height="calc(100vh - 480px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="businessName" label="配送员信息" width="200" align="center">
        </el-table-column>
        <el-table-column prop="linkPhone" label="骑手账号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="scope" label="骑手归属" align="center">
        </el-table-column>
        <el-table-column prop="businessStatus" label="配送费总金额" align="center">

        </el-table-column>
        <el-table-column prop="industryType" label="基础配送费" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="小费" width="150" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="平台抽成" width="150" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="骑手结算金额" width="150" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="配送区域" width="150" align="center">
        </el-table-column>
        <!-- <el-table-column
          prop="auditStatus"
          label="审核状态"
          
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.auditStatus==1">审核中</el-button>
            <el-button type="text" v-else-if="scope.row.auditStatus==2">审核拒绝</el-button>
            <el-button type="text" v-else>审核通过</el-button>
          </template>
</el-table-column> -->
        <el-table-column prop=" " label="操作" width="120" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 对账明细 -->
      <el-table v-if="billdetail" :data="tableData" style="width: 100%" height="calc(100vh - 480px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="businessName" label="配送员信息" width="200" align="center">
        </el-table-column>
        <el-table-column prop="linkPhone" label="配送员账号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="配送区域" width="200" align="center">
        </el-table-column>
        <el-table-column prop="scope" label="骑手归属" width="140" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="订单编号" width="140" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="订单类型" width="100" align="center">
        </el-table-column>
        <el-table-column prop="businessStatus" label="配送费总金额" align="center">

        </el-table-column>
        <el-table-column prop="industryType" label="基础配送费" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="小费" width="120" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="商家承担" width="100" align="center">
        </el-table-column>
        <el-table-column prop="businessName" label="用户承担" width="100" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="平台抽成" width="120" align="center">
        </el-table-column>
        <el-table-column prop="applicantTime" label="骑手结算金额" width="120" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'second',
      billdetail: false,
      toplist: [{
        name: '全部',
        value: ''
      }, {
        name: '待审核',
        value: ''
      }, {
        name: '已通过',
        value: ''
      }, {
        name: '已拒绝',
        value: ''
      }],
      options: [
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "已通过",
        },
        {
          value: 3,
          label: "已拒绝",
        },
      ],
      options1: [
        {
          value: 1,
          label: "团购",
        },
        {
          value: 2,
          label: "外卖",
        },
        {
          value: 3,
          label: "帮买",
        },
        {
          value: 4,
          label: "帮送",
        },
      ],
      tableData: [{
        id: 122,
        businessName: '十八点',
        allowPayoutsNum: 1888,
        payoutsTotalNum: 8821,
        linkPhone: 15088762311,
        scope: '和您当前',
        industryType: 1,
        businessStatus: 1,
        licenceImg: './img',
        idCardImgFront: './img',
        idCardImgReverse: './img',
        qualifications: './img',
        applicantTime: '1998-09-80',
        auditStatus: '2'
      }],
      queryInfo: {
        condition: {
          id: '',
          businessName: '',
          applicantTime: '',
          linkPhone: '',
          scope: '',
          industryType: '',
          businessStatus: ''
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      total: 0,
      showDialog: false,
      businessdetail: {},
      id: '',
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    change(e) {
      console.log(e);
    },
    download() {
      return
      this.$router.push({ path: '/riderdetail' })
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/businessTransaction/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    deta(id) {
      this.id = id
      var that = this;
      this.billdetail = true
      // this.$router.push({path:'/riderdetail',query:{
      //   id:id
      // }})
    },
    backlist() {
      this.billdetail = false
      this.reset()
    },
    del(id) {
      this.id = id
      var that = this;

      that.$http.post("/business/queryBusinessDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.businessdetail = response.data.data
        }
      });

    },
    datechange(e) { },
    search() {
      this.queryInfo.currPage = 1;
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        id: '',
        businessName: '',
        applicantTime: '',
        linkPhone: '',
        scope: '',
        industryType: '',
        businessStatus: ''
      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px 15px;

    .search-top {
      padding: 5px;
      background-color: rgba(30, 152, 215, 0.14);
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      div {
        width: 150px;
        text-align: center;
      }
    }

    .search-form {
      padding-right: 50px;
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 15px;
    padding: 15px
  }

  .bill-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .bill-info-item {
      padding: 0 20px;
      font-size: 13px;
      color: #606266;
    }
  }

  .btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/deep/ .el-switch__label * {
  font-size: 12px;
}
</style>